import { Theme, createStyles, withStyles } from "@material-ui/core/styles"

import classNames from "classnames"
import * as csx from "csx"
import { CSSProperties, PropsWithChildren } from "react"

import { ViewComponent } from "../base/ViewComponent"

export type TypographyVariant =
  | "largeHeading"
  | "heading"
  | "smallHeading"
  | "largeBody"
  | "body"
  | "description"
  | "buttonText"
  | "largeBold"
  | "bold"
  | "error"

export type ComponentType = "p" | "div" | "span" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "strong"

const styles = ({ palette }: Theme) =>
  createStyles({
    largeHeading: {
      fontWeight: 500,
      fontSize: "72px",
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
      color: Typography.theme.colors.primary.toString()
    },
    heading: {
      fontWeight: 500,
      fontSize: "48px",
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
      lineHeight: "3.5rem",
      color: Typography.theme.colors.primary.toString()
    },
    smallHeading: {
      fontWeight: 400,
      fontSize: "36px",
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
      color: Typography.theme.colors.primary.toString()
    },
    largeBody: {
      fontSize: "22px"
    },
    body: {
      fontWeight: "normal",
      fontSize: "18px",
      color: Typography.theme.colors.primary.toString()
    },
    largeBold: {
      fontWeight: "bold",
      fontSize: "22px",
      color: Typography.theme.colors.primary.toString()
    },
    bold: {
      fontWeight: "bold",
      fontSize: "18px",
      color: Typography.theme.colors.primary.toString()
    },
    description: {
      fontWeight: "normal",
      fontSize: "20px",
      color: Typography.theme.colors.primary.toString()
    },
    gray: {
      fontWeight: "normal",
      fontSize: "18px",
      color: Typography.theme.colors.baseGray.toString()
    },
    info: {
      fontWeight: "normal",
      fontSize: "16px"
    },
    error: {
      color: Typography.theme.colors.errorColor.toString()
    },
    buttonText: {
      fontSize: "15px",
      fontWeight: "bold",
      textTransform: "uppercase"
    },
    primaryColor: {
      color: Typography.theme.colors.primary.toString()
    },
    primaryColorFaded: {
      color: Typography.theme.colors.primary.fade(0.8).toString()
    },
    tertiaryColor: {
      color: Typography.theme.colors.tertiary.toString()
    },
    primaryColorContrast: {
      color: Typography.theme.colors.primaryContrast.toString()
    },
    primaryColorContrastFaded: {
      color: Typography.theme.colors.primaryContrast.fade(0.9).toString()
    },
    tertiaryColorContrast: {
      color: Typography.theme.colors.tertiaryContrast.toString()
    },
    gutterBottom: {
      marginBottom: "0.5rem"
    },
    noWrap: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    },
    paragraph: {
      marginBottom: "1rem"
    },
    noMargin: {
      marginTop: csx.important(0),
      marginBottom: csx.important(0)
    },
    disabled: {
      color: Typography.theme.colors.disabled.toString(),
      cursor: "default"
    }
  })

export interface TypographyProps {
  variant?: TypographyVariant
  className?: string
  useContrastColor?: boolean
  component?: ComponentType
  noWrap?: boolean
  noMargin?: boolean
  gutterBottom?: boolean
  paragraph?: boolean
  disabled?: boolean
  classes?: any
  style?: CSSProperties | undefined
}

class Typography extends ViewComponent<PropsWithChildren<TypographyProps>> {
  render() {
    const { children, className, paragraph, style } = this.props

    const component = paragraph ? "p" : this.props.component || this.getDefaultComponent()

    switch (component) {
      case "div":
        return (
          <div
            style={style}
            className={classNames(this.getVariantClass(), this.getColorClass(), this.getOptionClasses(), className)}
          >
            {children}
          </div>
        )
      case "span":
        return (
          <span
            style={style}
            className={classNames(this.getVariantClass(), this.getColorClass(), this.getOptionClasses(), className)}
          >
            {children}
          </span>
        )
      case "h1":
        return (
          <h1
            style={style}
            className={classNames(this.getVariantClass(), this.getColorClass(), this.getOptionClasses(), className)}
          >
            {children}
          </h1>
        )
      case "h2":
        return (
          <h2
            style={style}
            className={classNames(this.getVariantClass(), this.getColorClass(), this.getOptionClasses(), className)}
          >
            {children}
          </h2>
        )
      case "h3":
        return (
          <h3
            style={style}
            className={classNames(this.getVariantClass(), this.getColorClass(), this.getOptionClasses(), className)}
          >
            {children}
          </h3>
        )
      case "h4":
        return (
          <h4
            style={style}
            className={classNames(this.getVariantClass(), this.getColorClass(), this.getOptionClasses(), className)}
          >
            {children}
          </h4>
        )
      case "h5":
        return (
          <h5
            style={style}
            className={classNames(this.getVariantClass(), this.getColorClass(), this.getOptionClasses(), className)}
          >
            {children}
          </h5>
        )
      case "h6":
        return (
          <h6
            style={style}
            className={classNames(this.getVariantClass(), this.getColorClass(), this.getOptionClasses(), className)}
          >
            {children}
          </h6>
        )
      case "p":
      default:
        return (
          <p
            style={style}
            className={classNames(this.getVariantClass(), this.getColorClass(), this.getOptionClasses(), className)}
          >
            {children}
          </p>
        )
    }
  }

  private getColorClass() {
    const { variant, useContrastColor, classes } = this.props
    switch (variant) {
      case "description":
        // Fade the description slightly tp de-emphasize it
        return useContrastColor ? classes.primaryColorContrastFaded : classes.primaryColorFaded
      default:
        return useContrastColor ? classes.primaryColorContrast : classes.primaryColor
    }
  }

  private getDefaultComponent(): ComponentType {
    switch (this.props.variant) {
      case "largeHeading":
        return "h1"
      case "heading":
        return "h2"
      case "smallHeading":
        return "h3"
      case "body":
      case "buttonText":
      default:
        return "p"
    }
  }

  private getOptionClasses() {
    const { classes, gutterBottom, noWrap, paragraph, noMargin, disabled } = this.props

    const options = []

    if (noWrap) options.push(classes.noWrap)

    if (gutterBottom) options.push(classes.gutterBottom)

    if (paragraph) options.push(classes.paragraph)

    if (noMargin) options.push(classes.noMargin)

    if (disabled) options.push(classes.disabled)

    return options
  }

  private getVariantClass() {
    const { variant, classes } = this.props

    switch (variant) {
      case "bold":
      case "largeBold":
      case "error":
      case "largeBody":
        return ["body", classes[variant]]
      default:
        return [classes[variant] ?? "body"]
    }
  }
}

export default withStyles(styles)(Typography)
