export interface LocalizationConfig {
  dateFormat: string
  dateTimeFormat: string
  decimalSeparator: string
  defaultLanguages: string[]
  defaultSurveyLanguages?: string[]
  enabledLanguages: string[]
  surveyLanguages?: string[]
}

export const baseLocalizationConfig: LocalizationConfig = {
  dateFormat: "DD.MM.YYYY",
  dateTimeFormat: "DD.MM.YYYY HH:mm",
  decimalSeparator: ".",
  defaultLanguages: ["en"],
  enabledLanguages: ["en", "fi"]
}

export const availableLanguages = ["da", "de", "en", "es", "et", "fi", "fr", "no", "pl", "pt", "sv", "zh"]
