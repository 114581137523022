interface AppConfig {
  airbrake_project_id: number
  airbrake_project_key: string
  default_languages: string[]
  default_survey_languages: string[]
  version: string
}

export const baseAppConfig: AppConfig = {
  airbrake_project_id: parseInt(import.meta.env.VITE_AIRBRAKE_PROJECT_ID),
  airbrake_project_key: import.meta.env.VITE_AIRBRAKE_PROJECT_KEY,
  default_languages: import.meta.env.VITE_DEFAULT_LANGUAGES.split(",") || ["en"],
  default_survey_languages: import.meta.env.VITE_DEFAULT_SURVEY_LANGUAGES.split(",") || ["en"],
  version: import.meta.env.VITE_APP_VERSION
}

export const environment: string = import.meta.env.MODE
export const isProduction: boolean = import.meta.env.PROD
