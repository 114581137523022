import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { WithStyles, createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"
import * as React from "react"

import BaseInput, { BaseInputProps } from "./BaseInput"

interface CheckboxProps extends BaseInputProps<boolean> {
  disabled?: boolean
  fullWidth?: boolean
  margin?: boolean
  name?: string
  onChange?: (value: boolean, name?: string) => void
  required?: boolean
}

const styles = () =>
  createStyles({
    formLabel: {
      color: CheckBox.theme.colors.baseGray.toString(),
      fontSize: CheckBox.theme.font.fontSize,
      fontStyle: "normal",
      fontWeight: 400
    },
    labelContainer: {
      paddingTop: "0.1em"
    },
    margin: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    },
    fullWidth: {
      width: "100%"
    }
  })

class CheckBox extends BaseInput<CheckboxProps & WithStyles<typeof styles>, {}> {
  get componentName() {
    return ["ui", "form", "Checkbox"]
  }

  protected get labelContainerClass() {
    return this.props.classes.labelContainer
  }

  render() {
    const { id, label, name, value, classes, margin, fullWidth, disabled, className, labelClass } = this.props

    return (
      <FormControlLabel
        className={classNames(fullWidth && classes.fullWidth, margin !== false && classes.margin, labelClass)}
        classes={{ label: classes.formLabel }}
        control={
          <Checkbox
            id={id}
            className={className}
            checked={!!value}
            color="secondary"
            disabled={disabled}
            name={name}
            onChange={this.onChange}
          />
        }
        label={label}
        onClick={event => event.stopPropagation()}
      />
    )
  }

  private onChange = ({ target: { checked, name } }: React.ChangeEvent<HTMLInputElement>) =>
    this.props.onChange?.(checked, name)
}

export default withStyles(styles)(CheckBox)
